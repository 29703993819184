import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production";
export default new Vuex.Store({
  state: {
    loading: false,
    footer: false
  },
  getters: {
    getLoadStatus: state => state.loading,
    footerShow: (state) => state.footer,
  },
  actions: {
    async toggleLoading({ commit }, loading) {
      console.log('toggleLoading ' + loading)
      commit('toggleLoading', loading)
    },
    async fetchKelulusan({commit}, {sekolah, kode_siswa}) {
      return new Promise((resolve, reject) => {
        commit('toggleLoading', true)
        const qs = require('qs')
        const data = qs.stringify({'kode_siswa': kode_siswa})
        axios
        .post('https://'+atob(sekolah.identifier)+'.sekolahan.id/api/'+(sekolah.id)+'/kelulusan', data)
        .then(resp => {
          resolve(resp.data)
          commit('toggleLoading', false)
        }).catch(err => {
          commit('toggleLoading', false)
          alert(err)
          reject(err)
        })
      })
    }
  },
  mutations: {
    toggleLoading: (state, loading) => (state.loading = loading),
  },
  modules: {
  },
  plugins: [
  ],
  strict: debug
})
