<template>
<div>
  <v-overlay v-if="getLoadStatus">
    <v-progress-circular
      :size="70"
      :width="7"
      color="purple"
      indeterminate
      ></v-progress-circular>
  </v-overlay>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getLoadStatus'])
  },
  created() {
    console.log('loading created')
  }
}
</script>
