import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/views/layout/Main.vue';

Vue.use(Router)

const routes = [
    {
        path: '/', 
        component: Layout, 
    }
]

export default new Router({
  mode: "history",
  routes
})