<template>
  <v-app>
    <loading-modal/>
    <Content/>
    <Footer/>
  </v-app>
</template>

<script>
import Content from './Content.vue';
import Footer from './Footer.vue';
import loadingModal from '@/components/loadingModal.vue';

export default {
  name: 'Layout',
  components: {
    Content,
    Footer,
    loadingModal
  },
  data: () => ({
    offLine: false
  }),
  methods: {
    handleConnectivityChange(status) {
      this.offLine = !status
    }
  }
};
</script>