<template>
  <v-main class="pt-1">
    <offline @detected-condition="handleConnectivityChange"></offline>
    <v-form v-model="valid" @submit.prevent="doSubmit">
      <v-container class="col-12 col-md-6 mx-auto">
        <v-img
          :src="require('../../assets/happy-diverse-students-celebrating-graduation-from-school.jpg')"
          class="my-3"
          contain
          height="200"
        />
        <v-autocomplete
            v-model="school_value"
            :items="school_items"
            outlined
            dense
            item-text="nama"
            item-value="id"
            return-object
            label="Cari Nama Sekolah"
            :rules="[rules.required]"
            :search-input.sync="search"
            :loading="isLoading"
          ></v-autocomplete>
          <v-text-field v-model="kode_siswa" label="NIS/NISN" clearable :rules="[rules.required]"></v-text-field>
          <v-btn :disabled="!valid" color="primary" class="mt-4" type="submit">Cek Kelulusan</v-btn>
      </v-container>
    </v-form>
    <v-container v-if="respon" class="col-12 col-md-6 mx-auto">
      <v-alert text :type="status == 1 ? 'info':'error'">
        <h3 class="headline">
          Informasi
        </h3>
        <div>{{message}}</div>
        <br/>
        <v-btn color="success" :href='url_pdf' target='_blank'>Unduh Dokumen Kelulusan</v-btn>
      </v-alert>
    </v-container>
  </v-main>
</template>

<script>
import offline from 'v-offline';
import axios from 'axios'
import { apiUrl } from '@/config';

export default {
  name: 'Content',
  components: {
    offline
  },
  data: () => ({
    respon: false,
    status: 0,
    message: '',
    kode_siswa: '',
    offLine: false,
    valid: false,
    value: true,
    rules: {
      required: value => !!value || 'Required.',
    },
    school_items: [],
    school_value: null,
    search: null,
    isLoading: false,
    url_pdf: ''
  }),
  mounted () {
    axios
      .get(apiUrl + '/sekolahdata')
      .then(response => (this.school_items = response.data.responseData.results))
  },
  watch: {
    search (val) {
      // Items have already been loaded
      // if (this.school_items.length > 0) return

      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      // Lazily load input items
      axios.get(apiUrl + '/sekolahdata?namasekolah=' + escape(val))
      .then(response => {
        if (response.data.responseData.results) {
          this.school_items = response.data.responseData.results
        }
        this.isLoading = false
      })
    },
  },
  methods: {
    handleConnectivityChange(status) {
      this.offLine = !status
    },
    doSubmit() {
      this.$store.dispatch('fetchKelulusan', {sekolah: this.school_value, kode_siswa: this.kode_siswa}).then((resp) => {
        console.log(resp)
        this.respon = true
        this.status = resp.status
        this.message = resp.message
        this.school_value = ''
        this.kode_siswa = ''
        this.url_pdf = resp.url_pdf
      })
    }
  }
};
</script>
